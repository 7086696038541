import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import preloadStatic from '@utils/preloadStatic';

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'admin') {
    return import('./js/entries/admin');
  }
  if (process.env.REACT_APP_BUILD_TARGET === 'app') {
    return import('./js/entries/app');
  }

  return Promise.reject(new Error(`No such build target: ${process.env.REACT_APP_BUILD_TARGET}`));
}

// TODO Original CRA template contains StrictMode wrapper
importBuildTarget().then(({ default: Environment }) => {
  preloadStatic();
  ReactDOM.render(
    <>
      {process.env.REACT_APP_GTMID && (
        <noscript>
          <iframe
            title="no title"
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTMID}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}
      <Environment />
    </>,
    document.getElementById('app')
  );
});
