import settings from '@utils/settings';

type Manifest = {
  files: Record<string, string>;
  entrypoints: string[];
};

const manifestMap = {
  admin: '/admin/asset-manifest.json',
  app: '/asset-manifest.json'
};

const PRELOAD_DELAY = 15000;
const BUILD_TARGET = process.env.REACT_APP_BUILD_TARGET as 'admin' | 'app';

export default function preloadStatic() {
  if (!(BUILD_TARGET in manifestMap) || settings.appEnv !== 'prod') return;
  setTimeout(() => {
    fetch(manifestMap[BUILD_TARGET] as string)
      .then((res) => res.json())
      .then(async ({ files }: Manifest) => {
        const codeAssets = Object.values(files).filter((file: string) => file.endsWith('.js') || file.endsWith('.css'));
        for (let i = 0; i < codeAssets.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await fetch(codeAssets[i]);
        }
      });
  }, PRELOAD_DELAY);
}
