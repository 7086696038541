declare global {
  interface Window {
    settings: {
      faye: {
        connectionUrl: string;
      };

      google: {
        analyticsId: null;
      };
      companyWebApiBaseUrl: string;
      backOfficeApiBaseUrl: string;
      globalAuth: {
        cwp: {
          baseUrl: string;
          clientId: string;
        };
        bo: {
          baseUrl: string;
          clientId: string;
        };
      };
      okta: {
        enabled: boolean;
        issuer: string;
        baseUrl: string;
        clientId: string;
      };
      lulTracker: {
        clientId: string;
      };

      appEnv: 'dev' | 'stage' | 'prod';
      nodeEnv: 'development' | 'production';
      biEnabled: boolean;
      gitCommitHash: string;
    };
  }
}

// Global variable from app/src/settings.js
export default window.settings;
